var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    [
      _c("div", { staticClass: "grid grid-cols-12 gap-4" }, [
        _c("div", { staticClass: "col-span-12" }, [
          _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
            _c("legend", { staticClass: "px-1 font-bol" }, [
              _vm._v(" " + _vm._s(_vm.$t("filtros")) + " "),
            ]),
            _c(
              "div",
              { staticClass: "flex items-center p-2 flex-wrap gap-4" },
              [
                _c("select-suggestion", {
                  ref: "select_questionnaire",
                  class: _vm.questionnaire ? "flex-grow" : "flex-grow required",
                  attrs: {
                    max: 20,
                    column: "name,description",
                    model: "ContentQuestionnaire",
                    label: _vm.$t("Questionnaire"),
                    appendClearOption: true,
                    placeholderText: "Digite o nome do questionário",
                  },
                  on: { "selected-model": _vm.updateGrid },
                  model: {
                    value: _vm.questionnaire,
                    callback: function ($$v) {
                      _vm.questionnaire = $$v
                    },
                    expression: "questionnaire",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.questionnaire,
                expression: "questionnaire",
              },
            ],
            staticClass: "col-span-12",
          },
          [
            _c("Grid", {
              ref: "grid",
              attrs: {
                service: _vm.service,
                route_name: "institutions",
                route_grid_path: "/questionnaire_pause/grid",
                fetchOnMounted: false,
                showExport: true,
                order_column: "user_name",
                exportFilename:
                  "Relatório Pausa - " +
                  (_vm.questionnaire && _vm.questionnaire.name),
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function (actions) {
                    return [
                      _c("feather-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top",
                            value: {
                              content: "Visualizar intervalos.",
                              delay: { show: 1000, hide: 100 },
                            },
                            expression:
                              "{\n              content: 'Visualizar intervalos.',\n              delay: { show: 1000, hide: 100 }\n            }",
                            modifiers: { top: true },
                          },
                        ],
                        attrs: {
                          icon: "EyeIcon",
                          svgClasses:
                            "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openDetails(actions.row)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          attrs: {
            fullscreen: true,
            active: _vm.showDetails,
            title: _vm.$t("pausas"),
          },
          on: {
            "update:active": function ($event) {
              _vm.showDetails = $event
            },
          },
        },
        [
          _vm.detailingAnswer
            ? _c(
                "vs-table",
                {
                  attrs: {
                    noDataText: "Não há pausa para este usuário.",
                    data: _vm.detailingAnswer.pause_intervals,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return _vm._l(data, function (interval) {
                            return _c(
                              "vs-tr",
                              { key: interval.start_at },
                              [
                                _c("vs-td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$utils.format.dateWithFormat(
                                          interval.start_at,
                                          "DD/MM/YYYY - kk:mm:ss"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("vs-td", [
                                  interval.end_at !== null
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$utils.format.dateWithFormat(
                                                interval.end_at,
                                                "DD/MM/YYYY - kk:mm:ss"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("div", [_vm._v(" - ")]),
                                ]),
                                _c("vs-td", [
                                  interval.duration !== null
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$utils.format.seconds.humanReadableDuration(
                                                _vm.getVm(),
                                                interval.duration
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("div", [_vm._v(" - ")]),
                                ]),
                                _c("vs-td", [
                                  _vm._v(
                                    " " + _vm._s(_vm.getStatus(interval)) + " "
                                  ),
                                ]),
                                _c("vs-td", [
                                  _c(
                                    "div",
                                    { staticClass: "_justification-column" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(interval.justification) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          })
                        },
                      },
                    ],
                    null,
                    false,
                    769810962
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [
                        _vm._v(" " + _vm._s(_vm.$t("inicio")) + " "),
                      ]),
                      _c("vs-th", [
                        _vm._v(" " + _vm._s(_vm.$t("retornou")) + " "),
                      ]),
                      _c("vs-th", [
                        _vm._v(" " + _vm._s(_vm.$t("Duration")) + " "),
                      ]),
                      _c("vs-th", [
                        _vm._v(" " + _vm._s(_vm.$t("situacao")) + " "),
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("data_viewer.keys.justification")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }