<template>
  <vx-card>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <fieldset class="p-2 pt-0 border border-grey">
          <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
          <div class="flex items-center p-2 flex-wrap gap-4">
            <select-suggestion
              :max="20"
              :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
              @selected-model="updateGrid"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('Questionnaire')"
              v-model="questionnaire"
              :appendClearOption="true"
              ref="select_questionnaire"
              placeholderText="Digite o nome do questionário"
            />
          </div>
        </fieldset>
      </div>
      <div class="col-span-12" v-show="questionnaire">
        <Grid
          ref="grid"
          :service="service"
          route_name="institutions"
          route_grid_path="/questionnaire_pause/grid"
          :fetchOnMounted="false"
          :showExport="true"
          order_column="user_name"
          :exportFilename="`Relatório Pausa - ${questionnaire && questionnaire.name}`"
        >
          <template v-slot:actions="actions">
            <feather-icon
              v-tooltip.top="{
                content: 'Visualizar intervalos.',
                delay: { show: 1000, hide: 100 }
              }"
              icon="EyeIcon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
              @click="openDetails(actions.row)"
            />
          </template>
        </Grid>
      </div>
    </div>
    <vs-popup :fullscreen="true" :active.sync="showDetails" :title="$t('pausas')">
      <vs-table
        v-if="detailingAnswer"
        noDataText="Não há pausa para este usuário."
        :data="detailingAnswer.pause_intervals"
      >
        <template slot="thead">
          <vs-th>
            {{ $t('inicio') }}
          </vs-th>
          <vs-th>
            {{ $t('retornou') }}
          </vs-th>
          <vs-th>
            {{ $t('Duration') }}
          </vs-th>
          <vs-th>
            {{ $t('situacao') }}
          </vs-th>
          <vs-th>
            {{ $t('data_viewer.keys.justification') }}
          </vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr
            :key="interval.start_at"
            v-for="interval in data"
          >
            <vs-td>
              {{ $utils.format.dateWithFormat(interval.start_at, 'DD/MM/YYYY - kk:mm:ss') }}
            </vs-td>
            <vs-td>
              <div v-if="interval.end_at !== null">
                {{ $utils.format.dateWithFormat(interval.end_at, 'DD/MM/YYYY - kk:mm:ss') }}
              </div>
              <div v-else>
                -
              </div>
            </vs-td>
            <vs-td>
              <div v-if="interval.duration !== null">
                {{ $utils.format.seconds.humanReadableDuration(getVm(), interval.duration) }}
              </div>
              <div v-else>
                -
              </div>
            </vs-td>
            <vs-td>
              {{ getStatus(interval) }}
            </vs-td>
            <vs-td>
              <div class="_justification-column">
                {{ interval.justification }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </vx-card>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
export default {
  components: { SelectSuggestion },

  data: () => ({
    service: null,
    questionnaire: null,
    detailingAnswer: null,
    showDetails: false
  }),

  watch: {
    questionnaire(val) {
      this.updateGrid()
    },
  },

  computed: {
    validFilters() {
      return this.questionnaire && this.questionnaire.id
    }
  },

  methods: {
    updateGrid() {
      if (this.validFilters) {
        this.grid().fillCustomFilters(
          [{key:'questionnaire_id', value: this.questionnaire.id}]
        )
        this.grid().fetchGridData(false)
      }
    },
    grid() {
      return this.$refs['grid']
    },
    openDetails(answer) {
      this.showDetails = true
      this.detailingAnswer = answer
    },
    getVm() {
      return this
    },
    getStatus(interval) {
      const endAt = this._.get(interval, 'end_at', null)
      return endAt !== null ? this.$t('finalizada') : this.$t('em-andamento')
    }
  },

  beforeMount() {
    this.service = ReportService.build(this.$vs)
  }
}
</script>

<style scoped>
._justification-column {
  max-height: 100px;
  @apply break-all;
  @apply overflow-y-scroll;
}
</style>